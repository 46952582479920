import "../styles/Header.css";
import React from "react";
import logo from "../img/ON-CloudPDF WEB.png";


function Header() {

  const handleImgClick = () => {
    window.location.href = "/";
  }

  return (
    <div className="header">
      <div className="header_left">
        <a className="logo" href="/">
          <img src={logo} alt="logo" onClick={handleImgClick} />
        </a>
        <a className="only_pdf_links" id="pdf_to_docx" href="/pdf-to-docx">PDF TO WORD</a>
        <a className="only_pdf_links" id="merge_pdf" href="/merge">MERGE PDF</a>
        <a className="only_pdf_links" id="split_pdf" href="/split">SPLIT PDF</a>
        <a className="only_pdf_links" id="pdf_to_img" href="/pdf-to-img">PDF TO IMG</a>
        <a className="only_pdf_links" id="pdf_to_scan" href="/pdf-to-scan">PDF TO SCAN</a>
        <a className="only_pdf_links" id="rotate_pdf" href="/rotate">ROTATE PDF</a>
      </div>

      <div className="header_right">
        <div className="dropdown">
          <div className="dropbtn" />
          <div className="dropdown-content">
            <div className="list_menu">
              <h1 className="nav_title">ORGANIZE PDF</h1>
              <a href="/split">Split PDF</a>
              <a href="/merge">Merge PDF</a>
              <a href="/rotate">Rotate PDF</a>
            </div>

            <div className="list_menu">
              <h1 className="nav_title">CONVERT FROM PDF</h1>
              <a href="/pdf-to-img">PDF to IMG</a>
              <a href="/pdf-to-docx">PDF to WORD</a>
              <a href="/pdf-to-scan">PDF to SCAN</a>
            </div>

            <a className="direct_links" href="/about-us">About Us</a>

            <a className="direct_links" href="/faq">FAQ</a>

          </div>
        </div>
      </div>
    </div >
  );
}

export default Header;